import './bootstrap';

import Autocomplete from  './autocomplete';
import { message } from 'laravel-mix/src/Log';

var clearTrigger = document.getElementsByClassName("clear_trigger");
var clearTrigges = Array.from(clearTrigger);

clearTrigges.forEach(function(target) {
    target.addEventListener('click', function() {
        var input = target.parentElement.getElementsByTagName('input');
        var inputs = Array.from(input);
        inputs.forEach(function(t) {
            t.value = "";
        });
    });
});


var prefectureTrigger = document.getElementsByClassName("prefecture-input");
var prefectureTrigges = Array.from(prefectureTrigger);


prefectureTrigges.forEach(function(target) {
    target.addEventListener('change', function() {
        var oReq = new XMLHttpRequest();
        oReq.addEventListener("load", function(){
            var citys = JSON.parse(this.responseText);
            console.log(citys);
            var input = target.parentElement.parentElement.parentElement.getElementsByClassName('city-input');
            var inputs = Array.from(input);
            inputs.forEach(function(t) {
                t.innerHTML = "";
                var option = document.createElement("option");
                option.text = "未定";
                option.value = "";
                t.appendChild(option);
                for (var i in citys) {
                    var option = document.createElement("option");
                    option.text = citys[i].city ;
                    option.value = citys[i].city;
                    t.appendChild(option);
                }
            });
        });
        if (target.value != "") {
            oReq.open("GET", process.env.MIX_APP_URL + "/address/city/" + target.value);
            oReq.send();
        }
    });
});


// var doropdownTogglerTigger = document.getElementsByClassName("autocomplite-drop");
// var doropdownTogglerTigges = Array.from(doropdownTogglerTigger);

if (typeof autocomplites !== "undefined") {
    for (var i in autocomplites) {
        var id = document.getElementById(i);
        const ac = new Autocomplete(id, {
            data: [{}],
            maximumItems: 10,
            threshold: 0,
            onSelectItem: ({label, value}) => {
                console.log("user selected:", label, value);
            }
        });
        autocomplites[i] = ac;
    }
    if (onChangeAutocomplite != undefined) onChangeAutocomplite();
}


var baggageButtonTrigger = document.getElementsByClassName("shortcut-baggage-button");
var baggageButtons = Array.from(baggageButtonTrigger);
baggageButtons.forEach(function(target) {
    target.addEventListener('click', function() {
        var comment = document.getElementById('comment');
        var text = target.getAttribute('text');
        comment.value = text.replaceAll('<br />', '\n');
        return true;
    });
});

var btnAlertTriggers = document.getElementsByClassName("btn-alert");
var btnAlertButtons = Array.from(btnAlertTriggers);
btnAlertButtons.forEach(function(target) {
    target.addEventListener("click", function(e) {
        message = target.getAttribute('message');
        if (message == undefined || message == null) message = '実行してもよろしいでしょうか?';
        var res = confirm(message);
        if (res) {
        } else {
            e.preventDefault();
        }
    });
});
